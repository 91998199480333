import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ReturnPolicy = () => (
  <Layout>
    <Seo title="Return Policy" />
    <div className="container py-5 text-white">
      <h1 className="h3 bank-font text-center">Return Policy</h1>
      <div className="row justify-content-center">
        <div className="col-8">
          <section className="mt-4">
            <p className="minimal-title">Shipping information</p>
            <p className="minimal-body">
              We offer free Canada & International tracked shipping.
            </p>
            <p className="minimal-body">
              Orders are shipped within a week after they’re placed and received
              on our system.
            </p>
            <ul className="minimal-body">
              <li>
                Please note that during busy periods shipping times may be
                affected and you may have to check with the courier as to their
                deadlines.
              </li>
              <li>All shipped orders are tracked.</li>
              <li>
                The estimated shipping time begins from the day your order is
                despatched.
              </li>
              <li>
                Please note that we are unable to make any changes or
                cancellations once the order has been placed.
              </li>
            </ul>
          </section>

          <section className="mt-4">
            <p className="minimal-title">Customs Fees</p>
            <p className="minimal-body">
              We are not responsible for any import or custom fees associated
              with your order, please check with your country’s regulations to
              see if there will be any additional costs.
            </p>
          </section>

          <section className="mt-4">
            <p className="minimal-title">Late or lost orders</p>
            <p className="minimal-body">
              When placing your order please make sure that you specify the
              correct delivery address as we (TawAlap) take no responsibility
              for missing parcels that have been sent to the wrong address.
            </p>
            <p className="minimal-body">
              Please note we do not refund or replace items that are tracked as
              being delivered. For this reason, we always recommend that you get
              items delivered to a secure address.
            </p>
          </section>

          <section className="mt-4">
            <p className="minimal-title">Returns & Exchanges</p>
            <p className="minimal-body">
              We don’t offer options for returns or exchanges. All sales are
              final.
            </p>
          </section>

          <section className="mt-4">
            <p className="minimal-title">Exchange Policy (Exception)</p>
            <p className="minimal-body">
              The only exception is for exchanging items that were damaged
              during the shipping process, for the same type of items.
            </p>
            <p className="minimal-body">
              For that we offer a 14 day exchange. This 14 day window begins
              after an order has arrived with the customer.
            </p>
            <p className="minimal-body">
              Orders sent back to us after the 14 day exchange window has passed
              will be returned to the customer.
            </p>
            <hr />
            <p className="minimal-body">
              Please note all items must be unworn with original packaging in
              order to receive an exchange.
            </p>
            <p className="minimal-body">
              After receiving the returned items to be exchanged and having
              verified them we will then send the replacement items.
            </p>
            <p className="minimal-body">
              Please note we are not responsible for paying any customs charges
              (import duty or tax) on any exchanges. You will be responsible for
              all costs and charges associated with returning your order. We
              recommend you return your order via a tracked shipping method, as
              we cannot exchange or refund lost returns.
            </p>
          </section>

          <section className="mt-4">
            <p className="minimal-title">How do I request an exchange?</p>
            <p className="minimal-body">
              Email us at{" "}
              <a
                href="mailto:shlama@tawalap.com"
                className="minimal-body mb-0 text-decoration-none"
                style={{ fontSize: 14 }}
              >
                shlama@tawalap.com
              </a>{" "}
              with your order's number and an explanation.
            </p>
            <p className="minimal-body">To qualify for an exchange:</p>
            <ul className="minimal-body">
              <li>Items must be unworn.</li>
              <li>Items must not be altered in any way.</li>
              <li>All tags and packaging must be included.</li>
              <li>
                And meet the Exchange Policy section's exception case &
                outlines.
              </li>
            </ul>
            <p className="minimal-body">
              Items that do not meet these requirements are not eligible.
            </p>
          </section>
        </div>
      </div>
    </div>
  </Layout>
)

export default ReturnPolicy
